var columns = [{
  title: '鉴定单号',
  dataIndex: 'orderno',
  key: 'orderno',
  // width: '7%',
  scopedSlots: {
    customRender: 'orderno'
  }
}, {
  title: '品类',
  dataIndex: 'categoryname',
  key: 'categoryname',
  // width: '11%',
  scopedSlots: {
    customRender: 'categoryname'
  }
}, {
  title: '品牌',
  dataIndex: 'brandname',
  key: 'brandname',
  // width: '7%',
  scopedSlots: {
    customRender: 'brandname'
  }
}, {
  title: '图片',
  dataIndex: 'checkimg',
  key: 'checkimg',
  // width: '7%',
  scopedSlots: {
    customRender: 'checkimg'
  }
}, {
  title: '鉴定类型',
  dataIndex: 'type',
  key: 'type',
  // width: '7%',
  scopedSlots: {
    customRender: 'type'
  }
}, {
  title: '鉴定师',
  dataIndex: 'nickname',
  key: 'nickname',
  // width: '9%',
  scopedSlots: {
    customRender: 'nickname'
  }
}, {
  title: '提交时间',
  dataIndex: 'createtime',
  key: 'createtime',
  // width: '15%',
  scopedSlots: {
    customRender: 'createtime'
  }
}, {
  title: '状态',
  dataIndex: 'status',
  key: 'status',
  // width: '9%',
  scopedSlots: {
    customRender: 'status'
  }
}, {
  dataIndex: 'completetime',
  key: 'completetime',
  // width: '15%',
  slots: {
    title: 'completetimetext'
  },
  scopedSlots: {
    customRender: 'completetime'
  }
}, {
  title: '订单来源',
  dataIndex: 'sourcetype',
  key: 'sourcetype',
  // width: '13%',
  scopedSlots: {
    customRender: 'sourcetype'
  }
}, {
  title: '复审结果',
  dataIndex: 'resultstatustxt',
  key: 'resultstatustxt',
  // width: '13%',
  scopedSlots: {
    customRender: 'resultstatustxt'
  }
}, {
  title: '鉴定结果',
  dataIndex: 'resulttxt',
  key: 'resulttxt',
  // width: '8%',
  scopedSlots: {
    customRender: 'resulttxt'
  }
}
// {
//     title: '操作',
//     key: 'action',
//     dataIndex: 'action',
//     fixed: "right",
//     width: 200,
//     scopedSlots: {
//         customRender: 'action'
//     }
// }
];

export { columns };