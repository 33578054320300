import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll"
    },
    attrs: {
      bordered: false
    }
  }, [_c("DTab", {
    attrs: {
      defaultIndex: _vm.defaultIndex,
      titles: _vm.titles,
      handleClick: _vm.handleClick,
      showNumb: true
    }
  }), _c("a-form", {
    attrs: {
      form: _vm.createForm,
      layout: "inline"
    }
  }, [_c("FormItems", {
    attrs: {
      searchKeys: _vm.searchKeys,
      getDataWithKey: _vm.getDataWithKey,
      disabledDate: _vm.disabledDate
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn() {
        return [_c("div", [_c("a-button", {
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.handleSearch
          }
        }, [_vm._v("查询")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px",
            border: "0",
            background: "#f5f5f5"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.handleReset
          }
        }, [_vm._v("重置")]), _c("a-button", {
          staticStyle: {
            "margin-left": "20px"
          },
          attrs: {
            type: "primary",
            size: "large"
          },
          on: {
            click: _vm.downloadPost
          }
        }, [_vm._v("导出")])], 1)];
      },
      proxy: true
    }])
  })], 1)], 1), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: _vm.previewHandleCancel
    }
  }), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      loading: _vm.loading,
      rowClassName: _vm.setRowClassName
    },
    scopedSlots: _vm._u([_vm._l(_vm.columns, function (_ref) {
      var key = _ref.key;
      return {
        key: key,
        fn: function fn(text, record, index) {
          return [key == "checkimg" ? _c("span", [record.checkimg ? _c("img", {
            staticClass: "product_img",
            attrs: {
              src: _vm.coverImg(record.checkimg)
            },
            on: {
              click: function click($event) {
                return _vm.preview(record.checkimg);
              }
            }
          }) : _c("span", [_vm._v(" --- ")])]) : key == "sourcetype" ? _c("span", [record.sourcetype || record.sourcetype === 0 ? _c("span", [_vm._v(_vm._s(_vm.sourcetypeTxt(record.sourcetype)))]) : _c("span", [_vm._v("---")])]) : key == "orderno" ? _c("span", [_vm.pagePermissions.includes("/check/detail") ? _c("span", {
            staticStyle: {
              color: "#007aff",
              cursor: "pointer"
            },
            on: {
              click: function click($event) {
                return _vm.handleDetail(record);
              }
            }
          }, [_vm._v(_vm._s(record.orderno))]) : _c("span", [_vm._v(_vm._s(record.orderno))])]) : key == "completetime" ? _c("span", [_c("span", [_vm._v(_vm._s(record.completetime && _vm.status != 2 ? record.completetime : "---"))])]) : key == "type" ? _c("span", [_c("span", [_vm._v(_vm._s(_vm.typeTxt(record.type)))])]) : key == "status" ? _c("span", [_c("span", [_vm._v(_vm._s(_vm.statusTxt(record.status)))])]) : key == "resultstatustxt" ? _c("span", [_c("span", [_vm._v(_vm._s(record.resultstatustxt != " " ? record.resultstatustxt || "---" : "---"))])]) : key == "result" ? _c("span", [_c("span", {
            staticStyle: {
              color: "#3270f5"
            }
          }, [_vm._v(_vm._s(_vm.filterRecordValue(record[key])))])]) : _c("span", [_vm._v(_vm._s(_vm.filterRecordValue(record[key])))])];
        }
      };
    })], null, true)
  }, [_c("div", {
    attrs: {
      slot: "completetimetext"
    },
    slot: "completetimetext"
  }, [_vm._v(" " + _vm._s(_vm.status == 1 ? "预计结束时间" : "结束时间") + " ")])]), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };